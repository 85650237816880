//////////////////////////////////////////////////////////////////////
//
// These types must be kept in sync with the backend API types
// (backend/src/api.rs).
//

import { useState } from "react";
import { API_BASE_URL } from "./common";
import Link from "next/link";
import useSWR from "swr";

// A concept reference, which is a unique identifier for a concept.  Used
// for both general concepts (topics) and specific products.
export interface DisplayConceptRef {
  cref: string;
  display: string;
  overview: string;
}

// TODO: use consistently; there are places in the code base where ad-hoc
// structures are used instead of this.
export interface DisplayConceptDef {
  key: string;
  display_name: string;
  refd?: {
    gloss?: string;
    genus?: string;
    constraints?: Record<string, string>;
  };
  sup?: string[];
  sub?: string[];
}

// Provenance information for documents and feed items
export interface Provenance {
  createdon: string;
  creator: string;
}

// A document or post, as displayed to the user.
export interface DisplayDoc {
  key: string;
  text: string;
  topic: DisplayConceptRef;
  aspect: DisplayConceptRef;
  citations: Array<{
    doc: string;
    rel: string;
  }>;
  provenance: Provenance;
  visibility: string;
  concepts: DisplayMentionedConcept[];
}

// For attaching children to a document node.
export interface DocNode extends DisplayDoc {
  children: DocNode[];
}

// TODO: stop using this and use DisplayDoc instead.
// Define type of the json structure we get back from the guide endpoint
export interface GuideJsonResp {
  full: string;
  summary: string;
  full_doc_key: string;
}

// A concept mentioned in a document or post.
export interface DisplayMentionedConcept {
  mention: string;
  dcref: DisplayConceptRef;
  description: string;
  img_url: string;
}

// Define type of the json structure we get back
export interface ProductJsonResp {
  id: string;
  title: string;
  description: string;
  vendor: string;
  image_urls: string[];
  price?: number;
  currency?: string;
  customer_rating?: number;
}

// A thread of posts
export interface DisplayThread {
  docs: DisplayDoc[];
}

// A feed item
export interface FeedItem {
  datetime: string;   // timestamp
  thread: DisplayThread;  // In chrono order
  topics: DisplayConceptRef[];
  justification: FeedItemJustification;
}

// Justification for a feed item
export interface FeedItemJustification {
    // In case of the user being mentioned
    mentioner?: string;
    
    // In case of this being content the user follows
    friend?: string;  // Follower or followee
    topic?: DisplayConceptRef,
    
    // In case of this being a thread the user started or
    // added questions to
    is_owned: boolean;
}

// Info about a follow for a user (typically the current user).  Contains
// the username of the followed user, and the topics for the follow.
export interface DisplayUserFollow {
  username: string;
  topics: DisplayConceptRef[];
}

// Some additional info for the user that's generally useful in the UI.
export interface DisplayUser {
  username: string;
  fullname: string;
  email: string;
  link: string;
  communities: DisplayConceptRef[];
  following: DisplayUserFollow[];
}

export const EmptyDisplayUser: DisplayUser = {
  username: "",  // TODO: is this valid??
  fullname: "",
  email: "",
  link: "",
  communities: [],
  following: [],
};

// A request to join or leave communities.
export interface UpdateUserCommunitiesRequest {
  username: string;
  add_communities?: string[];
  rem_communities?: string[];
}

// A request to follow or unfollow a user.
export interface UpdateUserFollowsRequest {
  username: string;
  follow: string;
  action: "Follow" | "Unfollow" | "Update";
  topics?: string[];
}

//////////////////////////////////////////////////////////////////////
//
// Shared components that call the backend
//

// Shared fetcher with error handling
export const urlFetcher = (url: string) => fetch(url).then(res => {
    if (!res.ok) throw Error(res.statusText);
  return res.json();
});

// Not sure how widely these are used.  Also not sure if they should be
// used vs. the useSWR hook.
export enum LoadStatus {
  NoArg,
  Error,
  IsLoading,
  EmptyResponse,
  Success,
}

// Load a concept definition from the backend.
export function useGetCDef(topic: string): [LoadStatus, DisplayConceptDef | null] {
  const req_url = `${API_BASE_URL}/schema/${topic}`;
  const { data, error, isLoading } = useSWR(req_url, urlFetcher);

  if (topic === "") return [LoadStatus.NoArg, null];
  if (error) return [LoadStatus.Error, null];
  if (isLoading) return [LoadStatus.IsLoading, null];
  if (data.length == 0) return [LoadStatus.EmptyResponse, null];
  let cdef: DisplayConceptDef = JSON.parse(data);
  return [LoadStatus.Success, cdef];
}

interface UserPostsProps {
  posts: DisplayDoc[];
}

export const UserPosts: React.FC<UserPostsProps> = ({ posts }) => {
  if (!posts || posts.length === 0) return <p>No posts found</p>;

  return (
    <nav aria-label="User posts">
      <ul className="no-bullets">
        {posts.map((post: any, index: number) => (
          <li key={index}>
            <article>
              <header>
                <Link href={`/doc/${encodeURIComponent(post.key)}`}>
                  <time dateTime={post.provenance.createdon}>
                    {new Date(post.provenance.createdon).toLocaleDateString()}
                  </time>
                </Link>
                {post.topic && (
                  <>
                  <span aria-label="Topic">: topic:</span>
                  <Link href={`/guide/${encodeURIComponent(post.topic.cref)}`}>
                    {post.topic.display}
                  </Link>
                  </>
                )}
              </header>
              <p>{post.text}</p>
            </article>
          </li>
        ))}
      </ul>
    </nav>
  );
};

interface FollowingListProps {
  following: DisplayUserFollow[];
}

export const FollowingList: React.FC<FollowingListProps> = ({ following }) => {
  return (
    <nav aria-label="Following list">
      <ul>
        {following.map((follow, index) => (
          <li key={index}>
            <Link href={`/citizen/${encodeURIComponent(follow.username)}`}>
              {follow.username}
            </Link>
            {follow.topics.length > 0 && (
              <p>
                <span id={`topics-label-${index}`}>Topics:</span>
                <span aria-labelledby={`topics-label-${index}`}>
                  {follow.topics.map((c) => c.display).join(', ')}
                </span>
              </p>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

//////////////////////////////////////////////////////////////////////
//
// Shared functions for calling the backend
//

export async function updateUserCommunities(
  username: string,
  req_fields: Partial<UpdateUserCommunitiesRequest>
): Promise<DisplayUser> {
  const request: UpdateUserCommunitiesRequest = {
    username,
    ...req_fields,
  };

  const resp = await fetch(`${API_BASE_URL}/user/set_communities`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(request),
  });

  if (!resp.ok) {
    throw new Error(`Error modifying community: ${resp.statusText}`);
  }

  return resp.json();
}

export async function updateUserFollows(
  username: string,
  req_fields: Partial<UpdateUserFollowsRequest>
): Promise<DisplayUser> {
  const request: UpdateUserFollowsRequest = {
    username,
    follow: '',
    action: 'Follow',
    ...req_fields,
  };

  const resp = await fetch(`${API_BASE_URL}/user/set_follows`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(request),
  });

  if (!resp.ok) {
    throw new Error(`Error modifying follows: ${resp.statusText}`);
  }

  return resp.json();
}

